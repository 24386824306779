import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Badge, Alert, Container, Button } from 'react-bootstrap';
import { FaDownload, FaFolder, FaFilePowerpoint, FaFileExcel, FaFilePdf, FaEye, FaFirefoxBrowser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Highlighter from "react-highlight-words";
import { API_ENDPOINT } from '../utils/endpoint';
import moment from 'moment';


export const NewsCard = (article) => {

	const [readMore, setReadMore] = useState(false)

	let shortendedContent = article.article.content ? article.article.content.split(/(^.*?[a-z]{2,}[.!?])\s+\W*[A-Z]/).slice(1, 2) : ''
	return (
		<Card className=" card flex-row  m-2" >
			<Card.Header className={"Web border-0"} style={{ backgroundColor: '#FF9500' }}>
				<div style={{ verticalAlign: "middle" }}>
					<FaFirefoxBrowser size={50} color="white"></FaFirefoxBrowser>
				</div>
			</Card.Header>
	
			<Card.Body>
			<h4><a href={article.article.url} target="_blank">{article.article.title}</a></h4>
				<p>{readMore === false ? shortendedContent: article.article.content}<Button variant="link" style={{display: "inline"}} onClick = {(e) => setReadMore(!readMore)}>{readMore === true ? "Read Less" : "Read More"}</Button></p>
				<Row>
				<Col>
					{article.article.score && <Badge variant={article.article.score >= .88 ? "success": "warning"}>{Math.round(article.article.score * 100) + "% relevant"}</Badge>}
					</Col>
					<Col>
						{article.article.tags && article.article.tags.map(t => {
							return <Badge className="ml-2" variant="info">{t} </Badge>
						})}
					</Col>
				</Row>
				<hr></hr>
				<Row className="align-items-start" style = {{fontSize: '10pt'}}>
					<Col>
						<strong>Publish Date: </strong>
						<span className="ml-2"> {moment(article.article.date).format('LL')} </span>
					</Col>
					<Col>
						<span className="ml-2 mt-1"><strong>Source: </strong> {article.article.content_source}</span>
					</Col>
					<Col>
					<a href={article.article.url} ><FaDownload ></FaDownload>
						<span className="ml-2 mt-1"><strong>Go to Article </strong></span></a>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)

}



